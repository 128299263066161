import Vue from 'vue';
import axios from 'axios';
import UserStore from '@/store/UserStore';
import ErrorHandler from '@/plugins/ErrorHandler';
import App from '@/views/App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

UserStore.init();

const http = axios.create({
  baseURL: '/api',
});

// redirect 401 errors to login page
http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      UserStore.logOut();
      router.push('/login');
    }
    return Promise.reject(error);
  },
);
Vue.prototype.$http = http;
Vue.prototype.$user = UserStore;
Vue.prototype.$error = ErrorHandler;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
