import Vue from 'vue';
import VueRouter from 'vue-router';
import UserStore from '@/store/UserStore';
import Login from '@/views/Login.vue';
import Cart from '@/views/Cart.vue';
import Customer from '@/views/Customer.vue';
import Order from '@/views/Order.vue';
import OrderView from '@/views/OrderView.vue';
import OrderPrint from '@/views/OrderPrint.vue';
import LazyLoad from '@/router/lazyload';

Vue.use(VueRouter);
const DEFAULT_TITLE = 'APP';

const routes = [
  {
    path: '/',
    name: 'home',
    component: LazyLoad('Home'),
    meta: { title: 'ABC-POS' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: '登录' },
  },
  {
    path: '/cart', name: 'Cart', component: Cart, meta: { title: 'Cart' },
  },
  {
    path: '/customer', name: 'Customer', component: Customer, meta: { title: 'Customer' },
  },
  {
    path: '/order', name: 'Sales Order', component: Order, meta: { title: 'Order' },
  },
  { path: '/order/:id', name: 'Sales Order View', component: OrderView },
  { path: '/order/:id/print', name: 'Print', component: OrderPrint },

];

// const paths = [CompanyRoutes, OrderRoutes, ProductRoutes, ShipmentRoutes,
//   UserRoutes, VendorRoutes, PurchaseOrderRoutes];
const paths = [];
function addToRoute(modules) {
  modules.forEach((module) => {
    module.forEach((e) => {
      routes.push(e);
    });
  });
}
addToRoute(paths);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    if (!UserStore.isAuthenticated()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
