<template>
  <v-app id="inspire">
    <v-snackbar
      v-model="snackbar"
      :timeout="4000"
      color="error"
    >
      {{ snackbarText }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        x
      </v-btn>
    </v-snackbar>

    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form
                  id="login-form"
                  ref="form"
                  v-model="valid"
                  @submit.prevent="login()"
                >
                  <v-text-field
                    v-model.trim="username"
                    label="Username"
                    name="username"
                    prepend-icon="mdi-account"
                    type="text"
                    autocomplete="username"
                  />

                  <v-text-field
                    id="password"
                    v-model="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="current-password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="!valid"
                  type="submit"
                  color="primary"
                  form="login-form"
                  :loading="loading"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import bus from '@/eventBus';

export default {
  data: () => ({
    snackbar: false,
    snackbarText: '',
    valid: false,
    username: '',
    password: '',
    loading: false,
    showPassword: false,
  }),
  methods: {
    login() {
      if (!this.username || !this.password) { return; }
      this.loading = !this.loading;

      this.$http
        .post('user/login/', {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          const userData = response.data;
          this.$user.logIn(userData);
          this.loading = false;
          this.$router.push('/');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
      this.loading = false;
    },
  },
};
</script>
