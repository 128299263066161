<template>
  <div>
    <v-spacer />
    <v-card>
      <v-card-title>
        Customer Search
        <CustomerSearch @query="query" />
      </v-card-title>
    </v-card>
    <v-card>
      <v-card-title>Profile:
        <strong class="blue--text ml-2">{{profileName}}</strong></v-card-title>
    </v-card>
    <div v-if="emptyReturn">{{ errorMessage }}</div>
    <v-container>
      <div>
      <v-card  class="my-3">
        <v-btn  class="my-2 green"
        v-if="!profileName"
        @click="newProfile" >New Customer Profile</v-btn>
        <v-btn  class="my-2 red"
        v-else
        @click="removeProfile">Remove Profile</v-btn>
      </v-card>
      </div>
      <div class="mb-5">
        <v-card v-for="item in items" :key="item.id" class="mb-10">
          <v-row class="mt-1 ml-2">
            <strong class="blue--text">{{item.cus_nm}} ({{item.priceType}})</strong>
            <v-icon class="ml-3" color="orange"
            v-if="!profileName"
            @click="confirmMatch(item)">
              mdi-lock-open-plus</v-icon>
          </v-row>
          <v-row class="mt-1 ml-2">
            <div>{{item.address}}</div>
          </v-row>
          <v-row class="mt-1 ml-2">
            <div>{{item.city}}, {{item.state}} {{item.zip}}</div>
          </v-row>
          <v-row class="mt-1 ml-2">
            <div>{{item.phone}}</div>
          </v-row>
          <v-row class="mt-1 ml-2">
            <div>{{item.email}}</div>
          </v-row>
        </v-card>
      </div>
    </v-container>

  </div>
</template>
<style>
.align-center {
  text-align: center;
}
span {
  margin-left: 5px;
  margin-right: 5px;
}
</style>

<script>
import bus from '@/eventBus';
import CustomerSearch from '@/components/CustomerSearch.vue';
import rule from '@/library/rule';

export default {
  components: { CustomerSearch },
  data() {
    return {
      rule,
      emptyReturn: false,
      errorMessage: 'No customer found',
      items: [],
    };
  },
  created() {
  },
  computed: {
    profileName() {
      const c = localStorage.getItem('customer');
      if (c) {
        const customer = JSON.parse(c);
        return `${customer.cus_nm} (${customer.priceType})`;
      }
      return '';
    },
  },
  methods: {
    query(e) {
      this.$http
        .get(`/customer/${e}`)
        .then((response) => {
          this.items = [];

          const { data } = response;
          this.items = data;
          this.emptyReturn = this.items.length === 0;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmMatch(customer) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Start ordering as this customer ?')) {
        const customerLocalStorage = localStorage.getItem('customer');
        if (!customerLocalStorage) {
          const c = customer;
          c.isNewCustomer = false;
          localStorage.removeItem('orderInfo');
          const data = JSON.stringify(c);
          localStorage.setItem('customer', data);
          localStorage.setItem('transferred', JSON.stringify(false));
          this.$router.push('/');
        }
      }
    },
    newProfile() {
      const c = JSON.stringify({
        cus_nm: 'NEW CUSTOMER',
        priceType: 1,
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        isNewCustomer: true,
      });
      localStorage.setItem('customer', c);
      localStorage.removeItem('orderInfo');
      localStorage.setItem('transferred', JSON.stringify(false));
      this.$router.push('/');
    },
    removeProfile() {
      // eslint-disable-next-line no-alert
      if (window.confirm('this will remove cart as well !')) {
        localStorage.removeItem('customer');
        localStorage.removeItem('orderInfo');
        localStorage.setItem('cart', JSON.stringify([]));

        localStorage.setItem('transferred', JSON.stringify(false));
        this.$router.push('/');
      }
    },
  },
};
</script>
