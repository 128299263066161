export const DateParser = {
  ISO8601ToMMDDYY(v) {
    if (!v) return '';
    const d = v.substr(0, 10).split('-');
    return `${d[1]} / ${d[2]} / ${d[0].substr(2)}`;
  },
};

export const Formatter = {
  numberWithComma(v) {
    return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
};
