<template>
  <v-container>
  <div id='main-doc'>
    <v-container>
      <strong>
        ABC Trading Inc  (ASD Booth: SU52053)
      </strong>
      <div>2100 E 25th St, Vernon CA 90058</div>
      <div>323-581-3688</div>
      <div>abctradingabc@gmail.com</div>
      <div>www.abctradinginc.com</div>
      <v-card flat>
      <v-card-title>
        Sales Order # {{order.num}}
        <span v-if="order.processed"> /
          Linked SO#: <span class='red--text'>{{order.soNumber}}</span></span>
          <span class="d-print-none"> <v-btn text  x-large
          class="green"
        @click="openProcessDialog">
          Link/Unlink Order
        </v-btn></span>
        <v-spacer />

        <span class="d-print-none" v-if="!order.processed">
          <v-btn text icon x-large @click="edit">
          <v-icon> mdi-pencil </v-icon>
        </v-btn></span>
        <span class="d-print-none"> <v-btn text icon x-large @click="print">
          <v-icon> mdi-printer </v-icon>
        </v-btn></span>
      </v-card-title>
    </v-card>
    <div class="ml-2">
        <v-card  class="mx-10 text-body-2 pb-5">

                <span class="d-print-none"
                style="float:right"
                v-if="!order.processed">
          <v-btn text icon x-large @click="editInfo">
          <v-icon> mdi-pencil </v-icon>
        </v-btn></span>
          <v-row class="mx-4" >
            <v-col cols="12"
              class="mb-n4">
            <strong>{{order.customerName}}</strong>
            <span v-if="order.isNewCustomer"
            class="d-print-none red--text"> (NEW)</span>
            </v-col>
            <v-col cols="4" md= "4" sm="6"
              class="mb-n4"
              v-if="isNotEmpty(order.contactName)">
            Contact: {{order.contactName}}
            </v-col>
            <v-col cols="4" md= "4"  sm="6"
            class="mb-n4"
              v-if="isNotEmpty(order.phone)">
                Phone #: {{order.phone}}
            </v-col>
            <v-col cols="4" md= "4"  sm="6"
              class="mb-n4"
              v-if="isNotEmpty(order.email)">
                Email: {{order.email}}
            </v-col>
          <!-- </v-row> -->
          <!-- <v-row class="mx-4" > -->

            <v-col cols="12" md= "6"  sm="12"
              class="mb-n4"
              v-if="isNotEmpty(order.customerAddress)">
                Addr: {{order.customerAddress}}
            </v-col>
            <v-col cols="12" md= "6"  sm="12"
            class="mb-n4"
            v-if="isNotEmpty(order.shippingAddress)">
            Shipp Addr: {{order.shippingAddress}}
          </v-col>

            <v-col cols="4" md= "6"  sm="12"
            class="mb-n4"
              v-if="isNotEmpty(order.shippingMethod)">
                Ship By: {{order.shippingMethod}}
            </v-col>
            <v-col cols="4" md= "4" sm="6"
              class="mb-n4"
              v-if="isNotEmpty(order.salesRep)">
            Sales Rep: {{order.salesRep}}
            </v-col>
            <v-col cols="4" md= "4" sm="6"
              class="mb-n4 d-print-none"
              v-if="isNotEmpty(order.paymentInfo)">
            Payment Info: {{order.paymentInfo}}
            </v-col>
          </v-row>
          <v-row class="mx-4 pb-4">
            <v-col cols="12"
              class="mb-n4"
              v-if="isNotEmpty(order.note)">
            Note: {{order.note}}
            </v-col>
            </v-row>
            <v-spacer />

        </v-card>
      </div>
    </v-container>
    <v-data-table
      :headers="getHeaders"
      :items="order.items"
      sort-by="sku"
      class="elevation-1 mt-4"
      disable-pagination
      mobile-breakpoint="0"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="blue-grey lighten-2 ">
          <v-toolbar-title>Products</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
        </v-toolbar>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <a  :href="`/api/image/${item.image_nm}`" v-if="!printing">
          <v-img
          v-if="!!item.image_nm"
          :src="'/api/image/thumbnail/'+item.image_nm"
          max-height="150"
          max-width="150"
          contain
        />
      </a>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        $ {{ item.price }}
      </template>
      <template v-slot:[`item.pc_case`]="{ item }">
        {{ item.pc_case }} {{item.unit_nm}}
      </template>
      <template v-slot:[`item.subtotal`]="{ item }">
       $ {{ (item.pc_case * item.qty * item.price).toFixed(2) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer>
        <v-divider />
    <span class="mr-n3">
      <v-row><v-col class="ml-2">
        Weight: {{order.totalWeight}} lb
        </v-col>
        <v-col><div class="text-right  pr-3">Number of Cases:
          <strong>{{order.totalCase}}</strong></div></v-col></v-row>
      <v-row><v-col class="ml-2">
        <div>Volumn:
          <strong>{{order.totalVolumn}}</strong> ft<sup>3</sup></div>
        </v-col><v-col><div class="text-right pr-3">Total:
          <strong>$ {{order.totalCost}}</strong></div></v-col></v-row>

    </span>
      </template>
    </v-data-table>

  </div>
  <v-dialog v-model="process.dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <div class="headline">Link with OMS </div>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <div class="headline mb-3">
                    input of 0 will remove link</div>
                  <v-form id="orderItemForm"
                  >
                  <v-row justify="center" align="center">
                    <v-col cols="6">
                      <v-text-field
                        v-model.number="process.soNumber"
                        label="SO #"
                        :rules="rule.integer"
                        type="number"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="process.dialog=false">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  form="editInfoForm"
                  @click="saveOrderInfo"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  <v-dialog v-model="process.dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <div class="headline">Link with OMS </div>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <div class="headline mb-3">
                    input of 0 will remove link</div>
                  <v-form v-model="validSO" id="orderItemForm"
                  >
                  <v-row justify="center" align="center">
                    <v-col cols="6">
                      <v-text-field
                        v-model.number="process.soNumber"
                        label="SO #"
                        :rules="rule.integer"
                        type="number"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="process.dialog=false">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  form="editInfoForm"
                  :disabled="!validSO"
                  @click="linkOrder"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="editInfoDialog" max-width="700px">
              <v-card>
              <v-card-title>
                <div class="headline">Edit Info </div>
              </v-card-title>
              <v-card-text>
                <v-container>
<span class="pl-4">
          <v-row>
            <v-col cols="12" sm="6" class="mb-n8">
            <v-text-field
            v-model.trim="editInfoFields.customerName"
            outlined
            type="text"
            label="Company Name" />
          </v-col>
          <v-col cols="12" sm="6" class="mb-n8">
            <v-text-field
            v-model.trim="editInfoFields.customerAddress"
            outlined
            type="text"
            label="Address" />
          </v-col>
          <v-col cols="12" md="6" class="mb-n8">
            <v-text-field
            v-model.trim="editInfoFields.shippingAddress"
            outlined
            type="text"
            label="Shipping Address" />
          </v-col>
          <v-col cols="12" md="6" class="mb-n8">
            <v-text-field
            v-model.trim="editInfoFields.shippingMethod"
            outlined
            type="text"
            label="Shipping Method" />
          </v-col>
          <v-col cols="6" sm="4" class="mb-n8">
            <v-text-field
            v-model.trim="editInfoFields.contactName"
            outlined
            type="text"
            label="Contact" />
          </v-col>
          <v-col cols="6" md="4" class="mb-n8">
            <v-text-field
            v-model.trim="editInfoFields.phone"
            outlined
            type="text"
            label="Phone #" />
          </v-col>
          <v-col cols="6" md="4" class="mb-n6">
            <v-text-field
            v-model.trim="editInfoFields.email"
            outlined
            type="text"
            label="Email" />
          </v-col>
          <v-col cols="6" md="4" class="mb-n6">
            <v-text-field
            v-model.trim="editInfoFields.salesRep"
            outlined
            :rules="rule.notEmpty"
            type="text"
            label="Sales Rep" />
          </v-col>
          <v-col cols="12" lg=" 6" md="8"  sm="12" class="mb-n6">
            <v-text-field
            v-model.trim="editInfoFields.paymentInfo"
            outlined
            label="Payment Info" />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="editInfoFields.note"
              outlined
              label="note"
              rows="2"
              auto-grow
              prepend-inner-icon="mdi-note"
            />
          </v-col>
        </v-row>
        </span>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="editInfoDialog=false">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  form="editInfoForm"
                  :disabled="!canSave"
                  @click="saveInfoEdit"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  </v-container>
</template>
<style>
 @media print and (width: 8.5in) and (height: 11in) {
  @page {
  margin: 1in;
  font-size: 50%;
  }
  #main-doc {
      text-size-adjust: 40%;
      }
}
</style>
<script>
import rule from '@/library/rule';
import bus from '@/eventBus';

export default {
  data: () => ({
    rule,
    items: [],
    printing: false,
    process: {
      dialog: false,
      soNumber: 0,
    },
    viewport: undefined,
    valid: false,
    validSO: false,
    dialog: false,
    visible: true,
    order: { customerName: '', soNumber: 0, items: [] },
    customerInfoTransferred: false,
    editedIndex: -1,

    editFields: {
      price: 0,
      qty: 0,
    },
    editInfoFields: {
      customerName: '',
      customerAddress: '',
      shippingAddress: '',
      shippingMethod: '',
      contactName: '',
      phone: '',
      email: '',
      salesRep: '',
      paymentInfo: '',
      note: '',
    },
    validInfoForm: true,
    editInfoDialog: false,
  }),
  computed: {
    getHeaders() {
      const headers = [
        { text: 'sku', align: 'center', value: 'sku' },
        { text: 'descrip', value: 'descrip' },

        { text: 'Units/Case', value: 'pc_case' },
        { text: 'Price/Unit', value: 'price' },
        { text: 'Cases', align: 'center', value: 'qty' },
        { text: 'Subtotal', value: 'subtotal' },
      ];
      if (!this.printing) {
        headers.splice(2, 0, { text: '', value: 'image', sortable: false });
      }

      return headers;
      // { text: '', value: 'action', sortable: false },
    },
    canSave() {
      if (this.editInfoFields.customerName.length <= 0) return false;
      if (this.editInfoFields.salesRep.length <= 0) return false;
      return true;
    },
    editedItem() {
      if (this.editedIndex < 0) {
        return {
          sku: '', descrip: '', price: 0, qty: 0, pc_case: 0, unit_nm: '',
        };
      }
      return this.items[this.editedIndex];
    },
    totalCase() {
      let c = 0;
      for (let i = 0; i < this.items.length; i += 1) {
        c += this.items[i].qty;
      }
      return c;
    },
    total() {
      let t = 0;
      for (let i = 0; i < this.items.length; i += 1) {
        const e = this.items[i];
        t += e.qty * e.price * e.pc_case;
      }
      return t.toFixed(2);
    },

  },
  created() {
    this.fetch();
    this.viewport = document.querySelector('meta[name=viewport]');
    window.onafterprint = () => {
      this.viewport.setAttribute('content', 'width=device-width,initial-scale=1.0');
      this.printing = false;
    };
  },
  methods: {
    edit() { // perfmoring edit will delete the order, and put back to cart
      // eslint-disable-next-line no-alert
      if (window.confirm('are you sure you want to edit?')) {
        const link = `/salesOrder/${this.$route.params.id}`;
        this.$http.delete(link).then((response) => {
          const cart = JSON.stringify(response.data.items);
          localStorage.setItem('cart', cart);
          const orderInfo = response.data;
          delete orderInfo.items;
          localStorage.setItem('orderInfo', JSON.stringify(orderInfo));
          localStorage.setItem('transferred', JSON.stringify(true));
          const cust = { cus_nm: orderInfo.customerName, priceType: orderInfo.priceType };
          localStorage.setItem('customer', JSON.stringify(cust));
          this.$router.push('/cart');
        }).catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
      }
    },
    editInfo() {
      const attributesToCopy = ['customerName', 'customerAddress',
        'shippingAddress', 'shippingMethod', 'contactName', 'phone',
        'email', 'salesRep', 'paymentInfo', 'note'];
      // eslint-disable-next-line array-callback-return
      attributesToCopy.map((e) => {
        this.editInfoFields[e] = this.order[e];
      });
      this.editInfoDialog = true;
    },
    saveInfoEdit() {
      const link = `/salesOrder/${this.$route.params.id}`;
      this.$http.post(link, this.editInfoFields).then((response) => {
        this.order = response.data;
        this.editInfoDialog = false;
      });
    },
    print() {
      this.printing = true;
      this.viewport = document.querySelector('meta[name=viewport]');
      this.viewport.setAttribute('content', 'width=1024,initial-scale=0.1');
      //
      setTimeout(() => {
        window.print();
      }, 500);
    },
    isNotEmpty(text) {
      if (text === undefined) return false;
      return !!text;
    },
    fetch() {
      const link = `/salesOrder/${this.$route.params.id}`;
      this.$http
        .get(link)
        .then((response) => {
          this.order = response.data;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => { });
    },
    close() {
      this.dialog = false;
    },
    transferCustomerInfo() {
      let c = localStorage.getItem('customer');
      if (!c) return;
      c = JSON.parse(c);
      this.info.customerName = c.cus_nm;
      this.info.customerAddress = `${c.address}, ${c.city}, ${c.state} ${c.zip}`;
      this.info.phone = c.phone;
      this.info.email = c.email;
      this.info.isNewCustomer = c.isNewCustomer;
      this.transferCustomerInfo = true;
      localStorage.setItem('transferred', JSON.stringify(true));
    },
    saveOrderInfo() {
      localStorage.setItem('orderInfo', JSON.stringify(this.info));
    },
    upload() {
      const body = { info: this.info, items: this.items };
      this.$http
        .post('/salesOrder', body, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          bus.$emit('success', 'Upload Successful');
          this.$router.push(`/salesOrder/${response.data._id}`);
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
      this.loading = false;
    },
    openProcessDialog() {
      this.process.soNumber = this.order.soNumber;
      this.process.dialog = true;
    },
    linkOrder() {
      this.$http
        .put(`/salesOrder/${this.$route.params.id}/linkOmsSO`, { soNumber: this.process.soNumber }, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          this.order = response.data;
          this.process.dialog = false;
        })
        .catch((error) => {
          bus.$emit('error', error.response.data.message);
        });
    },
  },
};
</script>
