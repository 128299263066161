export default {
  state: () => ({ orders: [] }),
  getters: {
    orderList(state) {
      return state.orders;
    },
  },
  mutations: {
    updateOrderList(state, newList) {
      state.orders = newList;
    },
    addOrder(state, newVendor) {
      if (state.orders.length === 0) return;
      const object = { _id: newVendor._id, name: newVendor.name };
      state.orders.push(object);
    },
    updateOrder(state, newItem) {
      if (state.orders.length === 0) return;
      for (let i = 0; i < state.orders.length; i += 1) {
        if (state.orders[i]._id === newItem._id) {
          state.orders[i].name = newItem.name;
        }
      }
    },
  },
  actions: {
  },

};
