<template>
  <v-toolbar dense>
    <v-text-field
      hide-details
      v-model.trim="search"
      :rules="rule.notEmpty"
      single-line
      placeholder="Name"
      v-on:keyup.enter="query"
      @focus="searchboxfocus=true" @blur="searchboxfocus=false"
    ></v-text-field>
    <v-btn icon @click="query" >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import bus from '@/eventBus';
import rule from '@/library/rule';

export default {
  data() {
    return {
      search: '', // match box value
      rule,
    };
  },
  created() {
  },
  methods: {
    query() {
      if (this.search.length <= 1) {
        bus.$emit('error', 'length too short');
        return;
      }
      this.$emit('query', this.search);
      this.search = '';
    },
  },
};
</script>
