import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import Vendor from './modules/vendor';
import Order from './modules/order';
import Product from './modules/product';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { Vendor, Order, Product },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
