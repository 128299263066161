<template>
  <v-container>
  <div>
    <v-container>

      <v-card flat>
      <v-card-title>
        Order info
        <v-spacer />
        <v-btn
          class="mr-6"
          color="primary"
          type="submit"
          form="form"
          :disabled="!validOrder"
          @click="saveOrder"
        >
          <v-icon>mdi-content-save</v-icon>  </v-btn>
        <v-btn
          class="mr-6"
          color="primary"
          type="submit"
          form="form"
          :disabled="!validOrder"
          @click="upload"
        >
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
      <!-- <v-card-text"> -->
        <span class="pl-4">
          <v-row>
            <v-col cols="12" sm="6" class="mb-n8">
            <v-text-field
            v-model.trim="info.customerName"
            outlined
            type="text"
            label="Company Name" />
          </v-col>
          <v-col cols="12" sm="6" class="mb-n8">
            <v-text-field
            v-model.trim="info.customerAddress"
            outlined
            type="text"
            label="Address" />
          </v-col>
          <v-col cols="12" md="6" class="mb-n8">
            <v-text-field
            v-model.trim="info.shippingAddress"
            outlined
            type="text"
            label="Shipping Address" />
          </v-col>
          <v-col cols="12" md="6" class="mb-n8">
            <v-text-field
            v-model.trim="info.shippingMethod"
            outlined
            type="text"
            label="Shipping Method" />
          </v-col>
          <v-col cols="6" sm="4" class="mb-n8">
            <v-text-field
            v-model.trim="info.contactName"
            outlined
            type="text"
            label="Contact" />
          </v-col>
          <v-col cols="6" md="4" class="mb-n8">
            <v-text-field
            v-model.trim="info.phone"
            outlined
            type="text"
            label="Phone #" />
          </v-col>
          <v-col cols="6" md="4" class="mb-n6">
            <v-text-field
            v-model.trim="info.email"
            outlined
            type="text"
            label="Email" />
          </v-col>
          <v-col cols="6" md="4" class="mb-n6">
            <v-text-field
            v-model.trim="info.salesRep"
            outlined
            :rules="rule.notEmpty"
            type="text"
            label="Sales Rep" />
          </v-col>
          <v-col cols="6" md="4" class="mb-n6">
            <v-textarea
            v-model.trim="info.paymentInfo"
            outlined
            rows="2"
              auto-grow
            label="Payment Info" />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="info.note"
              outlined
              label="note"
              rows="2"
              auto-grow
              prepend-inner-icon="mdi-note"
            />
          </v-col>
        </v-row>
        </span>
      <!-- </v-card-text> -->
    </v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="sku"
      class="elevation-1 mt-4"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="blue-grey lighten-2 ">
          <v-toolbar-title>Cart</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <div class="font-weight-bold mb-4 mt-2 ml-4 ">
              <v-row class="">{{editedItem.sku}}</v-row>
              <v-row class="">{{editedItem.descrip}}</v-row>
              <v-row>{{editedItem.pc_case}} {{editedItem.unit_nm}} / case
              </v-row>
              <v-row> ${{(editedItem.pc_case  * editFields.price).toFixed(2)}}
              / case</v-row>
              </div>
                  <v-form v-model="valid" id="orderItemForm"
                  >
                  <v-row justify="center" align="center">
                    <v-col cols="6">
                      <v-text-field
                        v-model.number="editFields.price"
                        label="Price"
                        name="price"
                        type="number"
                        outlined
                      />
                    </v-col>
                      <v-col cols="6">
                      <v-text-field
                        v-model.number="editFields.qty"
                        label="Qty"
                        name="numCase"
                        type="number"
                        step="1"
                        :rules="rule.positiveInteger"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  </v-form>
                  <v-row>Subtotal:
                    ${{(editedItem.pc_case * editFields.qty * editFields.price).toFixed(2)}}
              </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  form="orderItemForm"
                  :disabled="!valid"
                  @click="saveEdit"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <a  :href="`/api/image/${item.image_nm}`">
          <v-img
                :src="computeImageSrc(item.image_nm)"
                max-height="200"
                contain
              />
      </a>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        $ {{ item.price }}
      </template>
      <template v-slot:[`item.pc_case`]="{ item }">
        {{ item.pc_case }} {{item.unit_nm}}
      </template>
      <template v-slot:[`item.subtotal`]="{ item }">
       $ {{ (item.pc_case * item.qty * item.price).toFixed(2) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div>Total Cases:  {{totalCase}}</div>
    <div>Total Volumn:  {{totalVolumn}} ft<sup>3</sup></div>
    <div>Total Weight:  {{totalWeight}} lb</div>
    <div>Total:  ${{total}}</div>
  </div>
  <v-btn class="mt-4 red" @click="clearCart">Clear Cart</v-btn>
  </v-container>
</template>

<script>
// import bus from '@/eventBus';
import rule from '@/library/rule';
import bus from '@/eventBus';

export default {
  data: () => ({
    rule,
    canSave: false,
    items: [],
    valid: false,
    dialog: false,
    visible: true,
    info: {
      customerName: '',
      customerAddress: '',
      shippingAddress: '',
      shippingMethod: '',
      phone: '',
      email: '',
      salesRep: '',
      isNewCustomer: true,
      contactName: '',
      paymentInfo: '',
      note: '',
      soNumber: '',
      priceType: '',

    },
    customerInfoTransferred: false,
    headers: [
      { text: 'sku', align: 'center', value: 'sku' },
      { text: 'descrip', value: 'descrip' },
      { text: 'image', value: 'image', sortable: false },
      { text: 'Qty Per Case', value: 'pc_case' },
      { text: 'Price', value: 'price' },
      { text: 'Cases', value: 'qty' },
      { text: 'Case Vol', value: 'volumn' },
      { text: 'Weight', value: 'weight' },
      { text: 'Subtotal', value: 'subtotal' },
      { text: '', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editFields: {
      price: 0,
      qty: 0,
    },
  }),
  computed: {
    validOrder() {
      if (this.info.customerName.length <= 0) return false;
      if (this.items.length <= 0) return false;
      if (this.info.salesRep.length <= 0) return false;
      return true;
    },
    editedItem() {
      if (this.editedIndex < 0) {
        return {
          sku: '', descrip: '', price: 0, qty: 0, pc_case: 0, unit_nm: '',
        };
      }
      return this.items[this.editedIndex];
    },
    totalCase() {
      let c = 0;
      for (let i = 0; i < this.items.length; i += 1) {
        c += this.items[i].qty;
      }
      return c;
    },
    totalWeight() {
      let c = 0;
      for (let i = 0; i < this.items.length; i += 1) {
        c += (this.items[i].qty * this.items[i].weight);
      }
      return c.toFixed(2);
    },
    totalVolumn() {
      let c = 0;
      for (let i = 0; i < this.items.length; i += 1) {
        c += (this.items[i].qty * this.items[i].volumn);
      }
      return c.toFixed(2);
    },
    total() {
      let t = 0;
      for (let i = 0; i < this.items.length; i += 1) {
        const e = this.items[i];
        t += e.qty * e.price * e.pc_case;
      }
      return t.toFixed(2);
    },

  },
  created() {
    this.items = JSON.parse(localStorage.getItem('cart'));
    this.customerInfoTransferred = JSON.parse(localStorage.getItem('transferred'));
    if (!this.customerInfoTransferred) {
      this.transferCustomerInfo();
    }
    const savedInfo = localStorage.getItem('orderInfo');
    if (savedInfo) {
      const t = JSON.parse(savedInfo);
      this.info = t;
    }
  },
  methods: {
    computeImageSrc(src) {
      if (!!src && src.length >= 0) {
        return `api/image/thumbnail/${src}`;
      }
      return 'img/imgnotavail.jpg';
    },
    clearCart() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure you want to clear the cart?')) {
        this.items = [];
        localStorage.setItem('cart', JSON.stringify(this.items));
      }
    },
    close() {
      this.dialog = false;
    },
    deleteItem(item) {
      // eslint-disable-next-line no-alert
      if (window.confirm(`confirm remove ${item.sku} x ${item.qty}?`)) {
        const i = this.items.indexOf(item);
        this.items.splice(i, 1);
        localStorage.setItem('cart', JSON.stringify(this.items));
      }
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editFields.qty = this.items[this.editedIndex].qty;
      this.editFields.price = this.items[this.editedIndex].price;
      this.dialog = true;
    },
    saveEdit() {
      this.items[this.editedIndex].qty = this.editFields.qty;
      this.items[this.editedIndex].price = this.editFields.price;
      this.dialog = false;
    },
    transferCustomerInfo() {
      let c = localStorage.getItem('customer');
      if (!c) return;
      c = JSON.parse(c);
      this.info.customerName = c.cus_nm;
      this.info.customerAddress = `${c.address}, ${c.city}, ${c.state} ${c.zip}`;
      this.info.phone = c.phone;
      this.info.email = c.email;
      this.info.isNewCustomer = c.isNewCustomer;
      this.info.priceType = c.priceType;
      this.customerInfoTransferred = true;
      localStorage.setItem('transferred', JSON.stringify(true));
      localStorage.setItem('orderInfo', JSON.stringify(this.info));
    },
    saveOrder() {
      localStorage.setItem('orderInfo', JSON.stringify(this.info));
    },
    upload() {
      const body = { info: this.info, items: this.items };
      this.$http
        .post('/salesOrder', body, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          bus.$emit('success', 'Order Created');
          const link = `/order/${response.data}`;
          localStorage.removeItem('customer');
          localStorage.removeItem('orderInfo');
          localStorage.setItem('cart', JSON.stringify([]));
          localStorage.setItem('transferred', JSON.stringify(false));
          this.$router.replace(link);
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
      this.loading = false;
    },
  },
};
</script>
