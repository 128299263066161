<template>
  <nav>
    <v-app-bar app color="blue " dark>
      <a href="/"> <v-icon left> mdi-tag </v-icon><span class="white--text"></span></a>
      <a href="/customer"> <v-icon left> mdi-account </v-icon><span class="white--text"></span></a>
      <a href="/order" >  <v-icon left> mdi-calendar-text </v-icon></a>
      <v-spacer />
        <a href="/cart"> <v-icon left> mdi-cart </v-icon><span class="white--text">Cart</span></a>
      <!-- <v-btn v-if="!loggedIn" text @click="logOut()"> Logout -->
      <!-- </v-btn> -->
    </v-app-bar>
  </nav>

</template>
<style>
a {
  text-decoration: none;
}

</style>
<script>
export default {
  data() {
    return {
      drawer: true,
    };
  },
  created() {
  },
  methods: {
    logOut() {
      localStorage.removeItem('user');
      this.$router.push('/login');
    },
  },
  computed: {
    loggedIn() {
      return localStorage.getItem('user');
    },
  },
};
</script>

<style>
.submenu-text {
  font-size: 80%;
}
</style>
