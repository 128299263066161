<template>
  <div>
    <v-spacer />
    <v-card>
      <v-card-title>
        Orders
      </v-card-title>
    </v-card>

    <v-container>
      <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      disable-pagination
    >
    <template v-slot:[`item.num`]="{ item }">
                  <a :href="`/order/` + item._id">
                    {{ item.num }}</a
                  >
                </template>
      <template v-slot:[`item.totalCost`]="{ item }">
        $ {{ numberWithComma(item.totalCost.toFixed(2)) }}
      </template>
      <template v-slot:[`item.customerName`]="{ item }">
        {{item.customerName}}
        <span v-if="item.isNewCustomer" class="red--text">✩</span>
      </template>
    </v-data-table>
    </v-container>
  </div>
</template>
<style>
.align-center {
  text-align: center;
}
span {
  margin-left: 5px;
  margin-right: 5px;
}
</style>

<script>
import bus from '@/eventBus';
import { Formatter } from '@/library/utility';

export default {
  data() {
    return {
      items: [],
      itemPerPage: -1,
      headers: [
        { text: 'Number', align: 'center', value: 'num' },
        { text: 'Customer', align: 'center', value: 'customerName' },
        { text: 'Addr', align: 'center', value: 'customerAddress' },
        { text: 'Ship By', align: 'center', value: 'shippingMethod' },
        { text: 'Sales Order #', align: 'center', value: 'soNumber' },
        { text: 'Sales Rep', align: 'center', value: 'salesRep' },
        { text: 'Note', align: 'center', value: 'note' },
        { text: 'Total Case', align: 'center', value: 'totalCase' },
        { text: 'Total Price', align: 'center', value: 'totalCost' },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      const link = '/salesOrder';
      this.$http
        .get(link)
        .then((response) => {
          this.items = response.data.docs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
        });
    },
    ...Formatter,

  },
};
</script>
