export default {
  state: () => ({ products: [] }),
  getters: {
    productList(state) {
      return state.products;
    },
  },
  mutations: {
    updateProductList(state, newProductList) {
      state.products = newProductList;
    },
    addProduct(state, newProduct) {
      if (state.products.length === 0) return;
      const object = {
        _id: newProduct._id,
        value: `${newProduct.sku} - ${newProduct.name}`,
        name: newProduct.name,
        sku: newProduct.sku,
      };
      state.products.push(object);
    },
    updateProduct(state, updatedProduct) {
      if (state.products.length === 0) return;
      for (let i = 0; i < state.products.length; i += 1) {
        if (state.products[i]._id === updatedProduct._id) {
          state.products[i].value = `${updatedProduct.sku} - ${updatedProduct.name}`;
          state.products[i].name = updatedProduct.name;
          state.products[i].sku = updatedProduct.sku;
        }
      }
    },
  },
  actions: {
    initializeProducts({ state, commit }, vue) {
      if (state.products.length > 0) return;
      vue.$http.get('/product/store').then((response) => {
        response.data.forEach((e) => {
          e.value = `${e.sku} - ${e.name}`;
        });
        commit('updateProductList', response.data);
      });
    },
  },

};
