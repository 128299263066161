const UserStore = {
  data: {
    username: undefined,
    isAdmin: false,
  },

  // must be called when app is initiated
  init() {
    try {
      const data = JSON.parse(localStorage.getItem('user'));
      if (!data) return;
      this.data = data;
    } catch (e) {
      localStorage.removeItem('user');
    }
  },

  isAdmin() {
    return !!this.user.isAdmin;
  },

  isAuthenticated() {
    return !!this.data.username;
  },

  logIn(data) {
    this.data = data;
    localStorage.setItem('user', JSON.stringify(data));
  },

  logOut() {
    const data = JSON.parse(localStorage.getItem('user'));
    if (!data) localStorage.removeItem('user');
    sessionStorage.removeItem('vuex');
    this.data = { username: undefined, isAdmin: false };
  },
};

export default UserStore;
